import Layout from "components/layout"
import Markdown from "components/markdown"
import React from "react"

const md = `
# Privacy Policy

Welcome! Stay or Go allows you to understand your value to banks and on that basis, to negotiate better arrangements or choose a bank that is right for you. We understand that protecting your personal information is important especially when it comes to your finances.

This Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or otherwise collected by us, offline or online, including when we are providing our services to you, through analytics on our website and where you insert your information directly into our website or mobile application, or where we are otherwise communicating with you, such as through email or over the telephone (together, our **Services**). In this Privacy Policy **we**, **us** or **our** means Stay or Go Pty Ltd ABN 50 637 744 613 trading as Stay or Go.

### Personal information

The types of personal information we may collect about you include:

1. your name;
2. your contact details, including email address and/or telephone number;
3. your current bank or financial service provider and the products held with each current bank or provider;
4. information about the credit cards you hold including the type of card, average monthly spend and average monthly repayment;
5. your annual income before tax and income type;
6. your browser session and geo-location data, device and network information, statistics on page views and sessions, acquisition sources, search queries and/or browsing behaviour; 
7. information about your access and use of our Services, including through the use of Internet cookies, your communications with our online Services, the type of browser you are using, the type of operating system you are using and the domain name of your Internet service provider; and
8. any other personal information requested by us and/or provided by you or a third party.

We collect these types of personal information directly from you or from third parties, where it is impracticable or unreasonable for us to collect the personal information directly from you.

### Collection and use of personal information

We may collect, hold, use and disclose personal information for the following purposes:

1. to enable you to access and use our Services, associated applications and associated social media platforms;
2. to enable us to provide monitoring services in respect of your products and any deals selected by you;
2. to contact and communicate with you;
3. for internal record keeping and administrative purposes;
4. for analytics, market research and business development, including to operate and improve our Services, associated applications and associated social media platforms;
5. for advertising and marketing, including to send you promotional information about our products and services, and information about third parties that we consider may be of interest to you; and
6. to comply with our legal obligations and resolve any disputes that we may have.

### Disclosure of personal information to third parties

We may disclose personal information to:

1. third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers (including security and compliance providers), data storage, web-hosting and server providers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;
2. our employees, contractors and/or related entities;
3. our existing or potential agents or business partners;
4. anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;
5. courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;
6. third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you; and
7. third parties to collect and process data, such as Google Analytics or other relevant businesses. This may include parties that store data outside of Australia, likely in the United States.

**Google Analytics**: We have enabled Google Analytics Advertising Features including remarketing, integrated services, and impression, demographic and interest reporting. We and third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and third-party cookies (such as Google advertising cookies) or other third-party identifiers together. You can opt-out of Google Analytics Advertising Features including through Ads Settings, Ad Settings for mobile apps, or any other available means including using a Google Analytics Opt-out Browser add-on.

**Disclosure overseas**: We store all personal information in Australia. However, at times we may need to disclose this information to third party service providers which are located overseas. Where we do so, we aim to choose reputable service providers subject to substantially similar laws as the Privacy Act.

However, sometimes we may need to disclose information to service providers which are located in jurisdictions with privacy laws which may not be substantially similar to the Privacy Act. Mostly, this is de-identified information, such as the information processed by Google Analytics.

**Your consent**: By providing us with personal information, you consent to the disclosure of your information outside of Australia and acknowledge that we are not required to take reasonable steps to ensure that overseas recipients handle that personal information in compliance with Australian privacy law. You acknowledge that some overseas third parties may not be regulated by the Privacy Act and the Australian Privacy Principles contained in the Privacy Act and if the overseas third party engages in any act or practice that contravenes the Australian Privacy Principles, we will not be accountable under the Privacy Act and you will not be able to seek redress under the Privacy Act.

### How we treat personal information that is also sensitive information

Sensitive information is a sub-set of personal information that is given a higher level of protection under the Australian Privacy Principles. **Sensitive information** means information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation or practices, criminal records, health information or biometric information.

We do not currently collect any sensitive information about you and we will not collect sensitive information about you without first obtaining your consent.

### Your rights and controlling your personal information

**Your choice**: Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our Services.

**Information from third parties**: If we receive personal information about you from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.

**Restrict and unsubscribe**: If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. You may also unsubscribe from our e-mail database or opt-out of marketing communications by using the opt-out facilities provided in the communication.

**Access**: You may request details of the personal information that we hold about you. An administrative fee may be payable for the provision of such information.

**Correction**: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.

**Complaints**: If you wish to make a complaint about how we have handled your personal information, please contact us using the details below and provide us with full details of the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact the Office of the Australian Information Commissioner (OAIC). You can contact OAIC’s hotline on 1300 363 992 or visit their website at www.oaic.gov.au. OAIC has the power to investigate a complaint and make a determination.

### Storage and security

We are committed to ensuring that the personal information we collect is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the personal information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.

We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The transmission and exchange of information is carried out at your own risk. Although we take measures to safeguard against unauthorised disclosures of information, we do not warrant that the personal information we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.

### Cookies and web beacons

We may use cookies on our online Services from time to time. Cookies are text files placed in your computer's browser to store your preferences. Cookies, by themselves, do not tell us your email address or other personally identifiable information. However, they do allow third parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online media feeds as part of our retargeting campaigns. If and when you choose to provide our online Services with personal information, this information may be linked to the data stored in the cookie.

You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our online Services.

We may use web beacons on our online Services from time to time. Web beacons (also known as Clear GIFs) are small pieces of code placed on a web page to monitor the visitor’s behaviour and collect data about the visitor’s viewing of a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.

We may use Google Analytics to collect and process data. To find out how Google uses data when you use third party websites or applications, please see www.google.com/policies/privacy/partners/ or any other URL Google may use from time to time.

### Links to third party websites

Our Services may contain links to third party websites. We do not have any control over the content or operation of any third party websites and we are not responsible for the protection and privacy of any personal information which you provide whilst visiting third party websites. This Privacy Policy does not apply to and does not govern the activities of any third party website.

### Amendments

We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended version on our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.

**For any questions or notices, please contact our Privacy Officer at:**

Stay or Go Pty Ltd ABN 20 248 811 433
Email: <info@stayorgo.com.au>
Mail: Stay or Go, Stone & Chalk, 121 King St, Melbourne VIC 3000

**Last update**: 26 November 2021

`

export default function Privacy() {
  return (
    <Layout title="Privacy Policy | Stay or Go" description="We understand that protecting your personal information is important especially when it comes to your finances. Our Privacy Policy sets out our commitment." image="/images/Logo_SOG_Colour.png">
      <Markdown md={md} />
    </Layout>
  )
}
